import PropTypes from 'prop-types'
import useFeatures from 'Hooks/useFeatures'
import FeedPanel from 'Components/feed/FeedPanel'
import BlockSelect from 'Components/BlockSelect'
import PanelIcon from 'Images/icons/feed-outline.svg'
import GridIcon from 'Images/icons/feed.svg'
import PremiumIcon from 'Images/icons/premium-solid.svg'
import ElasticCarouselIcon from 'Images/icons/elastic-carousel.svg'
import GalleryWallIcon from 'Images/widget-bushwick.svg'

import styles from 'Components/feed/FeedPanel.module.scss'
import classNames from 'classnames'

export default function FeedPanelWidgetType({
  panelId,
  plan,
  feed,
  updateLocalFeedSetting,
  localWidgetSettings,
  widgetSettings,
  setLocalWidgetSettings,
  hasChanges,
  saveChanges,
  resetChanges,
  demoMode = false,
}) {
  const features = useFeatures()

  function updateWidgetType(type) {
    let newMaxPosts = 6

    if (type === widgetSettings.type) {
      resetChanges()
      return
    }

    switch (type) {
      case 'galleryWall': {
        switch (
          localWidgetSettings.breakpoints.default.galleryWallNamedLayout
        ) {
          case 'bushwick':
            newMaxPosts = 6
            break
          case 'tribeca':
            newMaxPosts = 14
            break
          default:
            newMaxPosts = 6
            break
        }

        updateLocalFeedSetting(
          'maxPosts',
          Math.min(newMaxPosts, plan.maxImagesPerFeed),
        )
        setLocalWidgetSettings({
          ...localWidgetSettings,
          ...widgetSettings,
          type,
          breakpoints: {
            default: {
              ...localWidgetSettings.breakpoints?.default,
              ...widgetSettings.breakpoints?.default,
              galleryWallNamedLayout:
                localWidgetSettings.breakpoints.default
                  .galleryWallNamedLayout || 'bushwick',
              galleryWallLayout: localWidgetSettings.breakpoints.default
                .galleryWallLayout || [
                '0,20,24,24',
                '24,0,44,44',
                '68,8,21,21',
                '18,44,28,28',
                '46,44,22,22',
                '68,29,32,32',
              ],
            },
          },
        })
        break
      }
      case 'elasticCarousel': {
        updateLocalFeedSetting('maxPosts', Math.min(18, plan.maxImagesPerFeed))

        setLocalWidgetSettings({
          ...localWidgetSettings,
          ...widgetSettings,
          type,
          maxWidth: 1000,
          iconColor: '#4a4a4a',
          textColor: '#4a4a4a',
          buttonColor: '#f4f4f4',
          buttonHoverColor: '#ececec',
          buttonIconColor: '#4a4a4a',
          buttonIconHoverColor: '#2e2e2e',
          breakpoints: {
            default: {
              ...localWidgetSettings.breakpoints.default,
              numPosts: Math.min(18, plan.maxImagesPerFeed),
              borderRadius: 20,
              applyBorderRadiusToContainer: true,
              gap: {
                x: 10,
                y: 10,
              },
              forcePostAspectRatio: false,
              postHeight: 250,
              showControls: true,
              controlsPosition: 'bottom',
              controlsJustification: 'right',
              showLabel: true,
              labelPosition: 'bottom',
              labelJustification: 'left',
              controlsMargin: 20,
              buttonBorderRadius: 42,
            },
          },
        })
        break
      }
      case 'klaviyo':
        if (!demoMode) {
          updateLocalFeedSetting('maxPosts', feed.settings.maxPosts)
        }
        setLocalWidgetSettings({
          ...localWidgetSettings,
          ...widgetSettings,
          type,
        })
        break
      default:
        if (!demoMode) {
          updateLocalFeedSetting('maxPosts', feed.settings.maxPosts)
        }
        setLocalWidgetSettings({
          ...localWidgetSettings,
          ...widgetSettings,
          type,
        })
    }
  }

  return (
    <FeedPanel
      panelId={panelId}
      width={400}
      icon={<PanelIcon />}
      title="Widget type"
      hasChanges={hasChanges}
      saveChanges={saveChanges}
      resetChanges={demoMode ? () => {} : resetChanges}
    >
      <div className={styles.setting}>
        <BlockSelect
          options={[
            {
              value: 'grid',
              title: (
                <div className={styles.widget_type_select}>
                  <div className={styles.widget_type_select__icon}>
                    <GridIcon />
                  </div>
                  <div className={styles.widget_type_select__copy}>
                    <div className={styles.widget_type_select__title}>
                      Flexible grid
                    </div>
                    <div className={styles.widget_type_select__text}>
                      Highly configurable grid layout with responsive
                      breakpoints
                    </div>
                  </div>
                </div>
              ),
            },
            {
              value: 'galleryWall',
              title: (
                <div className={styles.widget_type_select}>
                  <div className={styles.widget_type_select__icon}>
                    <GalleryWallIcon />
                  </div>
                  <div className={styles.widget_type_select__copy}>
                    <div className={styles.widget_type_select__title}>
                      Gallery wall
                    </div>
                    <div className={styles.widget_type_select__text}>
                      Unique and interesting designs. Go to Customize → Gallery
                      wall design for more layout options
                    </div>
                  </div>
                </div>
              ),
            },
            {
              value: 'elasticCarousel',
              badge: features.lacks('widgetType:elasticCarousel') ? (
                <>
                  <PremiumIcon /> Paid plans
                </>
              ) : null,
              title: (
                <div
                  className={classNames(styles.widget_type_select, {
                    [styles.widget_type_select__premium]: features.lacks(
                      'widgetType:elasticCarousel',
                    ),
                  })}
                >
                  <div className={styles.widget_type_select__icon}>
                    <ElasticCarouselIcon />
                  </div>
                  <div className={styles.widget_type_select__copy}>
                    <div className={styles.widget_type_select__title}>
                      Elastic carousel
                    </div>
                    <div className={styles.widget_type_select__text}>
                      Fluid, physics-based carousel with mouse and touch drag
                      support
                    </div>
                  </div>
                </div>
              ),
            },
          ]}
          value={localWidgetSettings.type}
          onChange={updateWidgetType}
          name="widget type"
          size="large"
        />
      </div>
    </FeedPanel>
  )
}

FeedPanelWidgetType.propTypes = {
  panelId: PropTypes.string,
  plan: PropTypes.object,
  updateLocalFeedSetting: PropTypes.func,
  localWidgetSettings: PropTypes.object,
  setLocalWidgetSettings: PropTypes.func,
  hasChanges: PropTypes.bool,
  saveChanges: PropTypes.func,
  resetChanges: PropTypes.func,
}
