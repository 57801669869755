import { useSlice } from 'State'

function withInfinity(val) {
  return val < 0 ? Infinity : val
}

export default function useLimits() {
  const { user, plans } = useSlice('user', 'plans')
  const planData = plans?.[user.plan] ?? {}
  const overrides = user.planOverrides ?? {}

  return {
    maxHashtagsPerFeed: withInfinity(planData.maxHashtagsPerFeed),
    maxImagesPerFeed: withInfinity(planData.maxImagesPerFeed),
    maxSources: withInfinity(planData.maxSources),
    maxViews: withInfinity(planData.maxViews),
    updateFrequency: planData.updateFrequency,
    ...overrides.limits,
  }
}
