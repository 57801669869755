import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classnames from 'classnames'
import { useSlice } from 'State'
import useWindowSize from 'Hooks/useWindowSize'
import Layout from 'Components/Layout'
import PageHeader from 'Components/PageHeader'
import SmartButton from 'Components/SmartButton'
import Switch from 'Components/Switch'
import PlanDetails from 'Components/PlanDetails'
import LiveChatButton from 'Components/LiveChatButton'
import TransitionWrapper from 'Components/TransitionWrapper'

import styles from './ChoosePlan.module.scss'

// const displayPlans = ['Free', 'Personal', 'Business', 'Enterprise', 'platform']
const displayPlans = ['Free', 'Personal', 'Business', 'Enterprise']

export default function ChoosePlan() {
  const windowSize = useWindowSize()
  const appState = useSlice('user', 'plans')
  const { planId } = useParams()
  const plans = appState.plans
  const { plan: currentUserPlan, stripeSubscriptionInterval } = appState.user
  const [selectedPlan, setSelectedPlan] = useState(planId)
  const [interval, setInterval] = useState('year')
  const selectedPlanObj = plans?.[selectedPlan]
  const selectedPlanIsUsageBased = selectedPlanObj?.type === 'usage_based'

  const navigate = useNavigate()

  /*
   *   Handle plan select
   */
  function handlePlanSelect(evt) {
    setSelectedPlan(evt.target.value)
  }

  useEffect(() => {
    if (planId && displayPlans.includes(planId)) return
    const currentIndex = displayPlans.indexOf(currentUserPlan)
    if (currentIndex === -1) setSelectedPlan('Business')
    const nextIndex =
      currentIndex + 1 === displayPlans.length
        ? displayPlans.length - 1
        : currentIndex + 1
    setSelectedPlan(displayPlans[nextIndex])
  }, [currentUserPlan, planId])

  /*
   *   Interval switch
   */
  const intervalSwitch = (
    <Switch
      toggle
      className={styles.interval_switch}
      checked={selectedPlanIsUsageBased || interval === 'month'}
      disabled={selectedPlanIsUsageBased}
      onChange={(status) => setInterval(status ? 'month' : 'year')}
      before={
        <div
          className={
            interval === 'year'
              ? styles.switch_label_current
              : styles.switch_label
          }
        >
          Annual
        </div>
      }
      after={
        <div
          className={
            interval === 'month'
              ? styles.switch_label_current
              : styles.switch_label
          }
        >
          Monthly
        </div>
      }
      values={['annual', 'monthly']}
      ariaLabel="Billing frequency"
    />
  )

  /*
   *   Plans
   */
  const planEls =
    plans && selectedPlan
      ? displayPlans
          .filter((plan) => plans[plan])
          .map((id) => {
            const plan = plans[id]
            const isUsageBased = plan.type === 'usage_based'
            let monthlyPrice = 0
            let annualPrice = 0

            if (isUsageBased) {
              monthlyPrice = plan.intervals.month?.cost ?? 0
              annualPrice = monthlyPrice * 12
            } else {
              monthlyPrice =
                interval === 'year'
                  ? plan.intervals.year.cost / 12
                  : plan.intervals.month.cost

              annualPrice =
                interval === 'year'
                  ? plan.intervals.year.cost
                  : plan.intervals.month.cost * 12
            }

            let intervalDisplayText = interval === 'year' ? 'Annual' : 'Monthly'

            if (isUsageBased) {
              intervalDisplayText = 'Monthly + usage'
            }

            return (
              <div
                key={id}
                className={classnames(styles.plan, {
                  [styles.small]: id === 'Free',
                })}
              >
                <input
                  className={styles.plan__radio}
                  id={id}
                  type="radio"
                  name="plan"
                  value={id}
                  onChange={handlePlanSelect}
                  checked={selectedPlan === id}
                />
                <label className={styles.plan__inner} htmlFor={id}>
                  <div className={styles.plan__title}>
                    {plan.title}{' '}
                    {id !== 'Free' && (
                      <span className={styles.plan__title_interval}>
                        {intervalDisplayText}
                      </span>
                    )}
                  </div>
                  {((id === currentUserPlan &&
                    interval === stripeSubscriptionInterval) ||
                    (id === currentUserPlan && id === 'platform') ||
                    (id === 'Free' && currentUserPlan === 'Free')) && (
                    <TransitionWrapper scale className={styles.plan__current}>
                      Current
                    </TransitionWrapper>
                  )}
                  {id !== 'Free' && (
                    <div className={styles.plan__price}>
                      ${monthlyPrice}
                      <span className={styles.plan__price_interval}> / mo</span>
                      <div className={styles.plan__annual_price}>
                        ${annualPrice}
                        <span className={styles.plan__price_interval}>
                          {' '}
                          / year
                        </span>
                      </div>
                    </div>
                  )}
                  {id === 'Free' && <div className={styles.plan__price}>-</div>}
                </label>
              </div>
            )
          })
      : [1, 2, 3, 4].map((i) => (
          <div key={i} className={styles.plan_loader}></div>
        ))

  /*
   *   Render
   */
  return (
    <Layout metaTitle="Pick a plan | Behold">
      <main className={styles.container}>
        <PageHeader
          title="Pick a plan"
          stacked={windowSize.width < 701}
          breadCrumbs={[
            { text: 'Account', link: '/account' },
            { text: 'Pick a plan' },
          ]}
        >
          <TransitionWrapper
            show={!selectedPlanIsUsageBased && interval === 'year'}
            scale
            className={styles.save_note}
          >
            Saving 20%
          </TransitionWrapper>
          {intervalSwitch}
        </PageHeader>

        <div className={styles.inner}>
          <div className={styles.sidebar}>
            <PlanDetails planId={selectedPlan} planObj={selectedPlanObj} />
            <LiveChatButton size="small" />
          </div>

          <div className={styles.plans}>
            <TransitionWrapper>
              {planEls}
              <div className={styles.plans__footer}>
                {plans && selectedPlan && (
                  <SmartButton
                    onClick={() =>
                      navigate(
                        `/choose-plan/${selectedPlan}-${selectedPlanIsUsageBased ? 'month' : interval}/confirm`,
                      )
                    }
                    color="dark_green"
                    size="large"
                    disabled={
                      (selectedPlan === currentUserPlan &&
                        interval === stripeSubscriptionInterval) ||
                      (selectedPlan === currentUserPlan &&
                        selectedPlan === 'platform') ||
                      (selectedPlan === currentUserPlan &&
                        selectedPlan === 'Free')
                    }
                  >
                    Go to Checkout
                  </SmartButton>
                )}
              </div>
            </TransitionWrapper>
          </div>
        </div>
      </main>
    </Layout>
  )
}
