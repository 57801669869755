/* eslint-disable no-undef */
import { db, auth, serverTimestamp } from 'Fire/firebaseInit'
import {
  updatePassword as updatePasswordInFirebase,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth'
import {
  query,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  where,
  updateDoc,
  setDoc,
  deleteDoc,
  limit,
} from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { clearFeedCache } from 'Helpers/instaApi'
import { getISOString } from 'Helpers/utils'
import generateFunId from 'Helpers/generateFunId'

let UID = null
const functions = getFunctions()

/*
 *   Set UID for use by other functions
 */
export function setUID(authUID) {
  UID = authUID
}

/*
 *   Subscribe to versions
 */
export function subscribeToVersions(callback) {
  return onSnapshot(
    doc(db, `internal/versions`),
    (snapshot) => {
      if (callback) callback(snapshot.data())
    },
    (error) => {
      console.log('subscribeToVersions', error)
    },
  )
}

/*
 *   Subscribe to announcement
 */
export function subscribeToAnnouncement(callback) {
  return onSnapshot(
    doc(db, `internal/announcement`),
    (snapshot) => {
      if (callback) callback(snapshot.data())
    },
    (error) => {
      console.log('subscribeToAnnouncement', error)
    },
  )
}

/*
 *   Subscribe to user
 */
export function subscribeToUser(userId = UID, callback) {
  return onSnapshot(
    doc(db, `users/${userId}`),
    (snapshot) => {
      if (callback) callback(snapshot.data())
    },
    (error) => {
      console.log('subscribeToUser', error)
    },
  )
}

/*
 *   Subscribe to protected user data
 */
export function subscribeToProtectedUserInfo(userId = UID, callback) {
  return onSnapshot(
    doc(db, `users/${userId}/protected/userInfo`),
    (snapshot) => {
      if (callback) callback(snapshot.data())
    },
    (error) => {
      console.log('subscribeToProtectedUserInfo', error)
    },
  )
}

/*
 *   Subscribe to payment methods
 */
export function subscribeToPaymentMethods(userId = UID, callback) {
  return onSnapshot(
    collection(db, `users/${userId}/paymentMethods`),
    (snapshot) => {
      const docIndex = {}

      if (snapshot.docs.length) {
        snapshot.docs.forEach((doc) => {
          const data = doc.data()
          docIndex[data.id] = data
        })
      }

      if (callback) callback(docIndex)
    },
    (error) => {
      console.log('subscribeToPaymentMethods', error)
    },
  )
}

/*
 *   Subscribe to payment receipts
 */
export function subscribeToPayments(userId = UID, callback) {
  return onSnapshot(
    collection(db, `users/${userId}/payments`),
    (snapshot) => {
      let docs = []

      if (snapshot.docs.length) {
        docs = snapshot.docs.map((doc) => doc.data())
      }

      if (callback) callback(docs)
    },
    (error) => {
      console.log('subscribeToPayments', error)
    },
  )
}

/*
 *   Get user monthly usage
 */
export function getMonthlyUserUsage(userId = UID, callback) {
  try {
    const q = query(
      collection(db, `users/${userId}/protected/userInfo/usage`),
      orderBy('year', 'desc'),
      limit(12),
    )
    return getDocs(q).then((snapshot) => {
      const docIndex = {}

      if (snapshot.docs.length) {
        snapshot.docs.forEach((doc) => {
          const data = doc.data()
          docIndex[doc.id] = data
        })
      }
      if (callback) callback(docIndex)
    })
  } catch (error) {
    console.log('getMonthlyUserUsage', error)
  }
}

/*
 *   Subscribe to authorized accounts
 */
export function subscribeToSources(userId = UID, callback) {
  return onSnapshot(
    collection(db, `users/${userId}/authorizedAccounts`),
    (snapshot) => {
      const docIndex = {}

      if (snapshot.docs.length) {
        snapshot.docs.forEach((doc) => {
          const data = doc.data()
          docIndex[doc.id] = data
        })
      }

      if (callback) callback(docIndex)
    },
    (error) => {
      console.log('subscribeToSources', error)
    },
  )
}

/*
 *   Subscribe to feeds
 */
export function subscribeToFeeds(
  userId = UID,
  callback,
  postsCallback,
  unsubscribes,
) {
  const q = query(
    collection(db, `users/${userId}/instagramFeeds`),
    where('archived', '==', false),
  )

  const unsubFromFeeds = onSnapshot(
    q,
    (snapshot) => {
      const docIndex = {}

      if (snapshot.docs.length) {
        snapshot.docs.forEach((snapshot) => {
          const data = snapshot.data()
          docIndex[data.id] = data
          unsubscribes.push(
            subscribeToFeedPosts({
              feedId: data.id,
              callback: postsCallback,
            }),
          )
        })
        if (callback) callback(docIndex)
      } else {
        if (callback) callback(docIndex)
      }
    },
    (error) => {
      console.log('subscribeToFeeds', error)
    },
  )

  return unsubFromFeeds
}

/*
 *   Subscribe to feed posts
 */
function subscribeToFeedPosts({ userId = UID, feedId, callback }) {
  const q = collection(
    db,
    `users/${userId}/instagramFeeds/${feedId}/cachedPosts`,
  )

  return onSnapshot(
    q,
    (snapshot) => {
      // This Map shenanigans is to deduplicate
      // Duplication can briefly occur during a refresh
      const posts = new Map()
      snapshot.docs.forEach((snapshot) => {
        const data = snapshot.data()
        data.posts.forEach((post) => posts.set(post.id, post))
      })
      const arrayOfPosts = Array.from(posts, ([_, value]) => value)
      if (callback) callback({ feedId, posts: arrayOfPosts })
    },
    (error) => {
      console.log('subscribeToFeedPosts', error)
    },
  )
}

/*
 *   Get feed usage
 */
export function getFeedUsage(userId = UID, callback) {
  try {
    const q = query(
      collection(db, `users/${userId}/instagramFeeds`),
      where('archived', '==', false),
    )
    return getDocs(q).then((snapshot) => {
      const docIndex = {}
      const today = new Date()
      const isoMonth = getISOString(today, 'month')

      if (snapshot.docs.length) {
        snapshot.docs.forEach((snapshot) => {
          const feedId = snapshot.id

          /*
           *   Get monthly usage
           */
          const monthlyUsageRef = doc(
            db,
            `users/${userId}/instagramFeeds/${feedId}/usage/`,
            isoMonth,
          )

          getDoc(monthlyUsageRef).then((snapshot) => {
            if (snapshot.exists()) {
              const snapData = snapshot.data()
              docIndex[feedId] = snapData ? snapData.total : 0
            } else {
              docIndex[feedId] = 0
            }
            if (callback) callback(docIndex)
          })
        })
      } else {
        if (callback) callback(docIndex)
      }
    })
  } catch (err) {
    console.log(err)
    return err
  }
}

/*
 *   Get plans
 */
export function getPlans(callback) {
  try {
    return getDocs(collection(db, 'plans')).then((snapshot) => {
      if (callback) callback(snapshot)
    })
  } catch (err) {
    console.log(err)
    return err
  }
}

/*
 *   Update user
 */
export async function updateUser(updates, userId = UID) {
  return updateDoc(doc(db, `users/${userId}`), updates)
}

/*
 *   Add payment method
 */
export async function addPaymentMethodToUser({
  id,
  customerId,
  name,
  type,
  brand,
  expMonth,
  expYear,
  lastFour,
}) {
  return setDoc(doc(db, `users/${UID}/paymentMethods`, id), {
    id,
    customerId,
    name,
    type,
    brand,
    expYear,
    expMonth,
    lastFour,
  })
}

/*
 *   Delete card
 */
export async function deleteUserCard({ cardId }) {
  return deleteDoc(doc(db, `users/${UID}/paymentMethods`, cardId))
}

/*
 *   Update authorized account
 */
export async function updateAuthorizedAccount(id, data, callback) {
  try {
    await updateDoc(doc(db, `users/${UID}/authorizedAccounts`, id), data)
    if (callback) callback()
  } catch (err) {
    console.error('Error updating authorized account: ', err)
  }
}

/*
 *   Update Instagram feed
 */
export async function updateInstagramFeed(feedId, feedData) {
  return await updateDoc(
    doc(db, `users/${UID}/instagramFeeds`, feedId),
    feedData,
  )
}

/*
 *   Delete authorized account
 *   Feeds are archived by an onDelete cloud function
 */
export async function deleteAuthorizedAccount(instagramUserId) {
  try {
    return await deleteDoc(
      doc(db, `users/${UID}/authorizedAccounts`, instagramUserId),
    )
  } catch (err) {
    console.log(err)
  }
}

/*
 *   Add feed
 */
export async function addFeed(
  {
    username,
    instagramUserId,
    source,
    accessToken,
    expiresOn,
    title,
    type,
    subtype = null,
    contentSource,
    hashtags,
    hashtagFeedType = 'TOP',
    hashtagFeedSort = 'MIX',
    followersCount,
    followsCount,
    mediaCount,
    profilePictureUrl,
    website,
    biography,
  },
  feeds,
) {
  try {
    const newFeedRef = doc(collection(db, `users/${UID}/instagramFeeds`))

    const userInfoSnap = await getDoc(
      doc(db, `users/${UID}/protected/userInfo`),
    )

    const paused = userInfoSnap.data().pausedByOverage
    const planSnap = await getDoc(doc(db, `plans/${userInfoSnap.data().plan}`))
    const planData = planSnap.data()

    // Define widget settings
    let widgetSettings = {}

    if (type === 'WIDGET') {
      widgetSettings = {
        type: 'grid',
        onHover: 'showIcon',
        hoverEffect: 'zoomFade',
        previewVideosOnHover: true,
        autoplayVideos: false,
        onPostClick: 'linkToPost',
        customLinkURL: '',
        linkTarget: '_blank',
        popupColorTheme: 'auto',
        hoverOverlayColor: 'default',
        hoverOverlayCustomColor: '0,0,0',
        hoverOverlayOpacity: 65,
        loadingColor: 'dominant',
        loadingColorTone: '225,73,57',
        constrainWidth: true,
        maxWidth: 650,
        alignment: 'center',
        breakpoints: {
          default: {
            numPosts: Math.min(planData.maxImagesPerFeed, 6),
            numColumns: 3,
            borderRadius: 0,
            gap: {
              x: 20,
              y: 20,
            },
            postAspectRatio: [1, 1],
          },
        },
      }
    }

    if (subtype === 'KLAVIYO') {
      const existingWebFeedIds = Object.values(feeds)
        .filter((feed) => feed.subtype === 'KLAVIYO')
        .map((feed) => feed.widgetSettings.klaviyoWebFeedId)

      widgetSettings = {
        klaviyoWebFeedId: generateFunId(existingWebFeedIds, 'behold_'),
        mobileStylesLinked: true,
        breakpoints: {
          DESKTOP: {
            customLinkURL: '',
            loadingColor: 'dominant',
            loadingColorTone: '225,73,57',
            onPostClick: 'linkToPost',
            numPosts: 4,
            numColumns: 4,
            borderRadius: '0',
            gap: {
              x: 0,
              y: 0,
            },
          },
          MOBILE: {
            customLinkURL: '',
            loadingColor: 'dominant',
            loadingColorTone: '225,73,57',
            onPostClick: 'linkToPost',
            numPosts: 3,
            numColumns: 3,
            borderRadius: '0',
            gap: {
              x: 0,
              y: 0,
            },
          },
        },
      }
    }

    // Create doc
    let numPosts = 6
    if (type === 'API') numPosts = 9
    if (subtype === 'KLAVIYO') numPosts = 4

    await setDoc(newFeedRef, {
      version: 2,
      id: newFeedRef.id,
      sourceId: source.parentSourceId ?? source.id,
      sourceType: source.type,
      sourceIsActive: source.active,
      title,
      type,
      subtype,
      instagramUserId,
      username,
      accessToken,
      expiresOn,
      pausedByOverage: paused,
      archived: false,
      inactive: false,
      isUpdating: true,
      newImagesAreProcessing: !source.reauthorize,
      settings: {
        maxPosts: Math.min(planData.maxImagesPerFeed, numPosts),
        filter: {
          allowedTypes: ['IMAGE', 'VIDEO', 'REEL', 'CAROUSEL_ALBUM'],
          captionIncludeText: [],
          captionExcludeText: [],
        },
        cacheLength: planData.updateFrequency,
        domainWhitelist: [],
        hashtagFeedType,
        hashtagFeedSort,
      },
      features: planData.features,
      created: serverTimestamp(),
      owner: UID,
      contentSource: contentSource,
      widgetSettings,
      followersCount: followersCount,
      followsCount: followsCount,
      mediaCount: mediaCount,
      profilePictureUrl: profilePictureUrl ?? null,
      website: website ?? null,
      biography: biography ?? null,
      ...(hashtags && { hashtags }),
    })

    clearFeedCache(newFeedRef.id)

    return newFeedRef.id
  } catch (err) {
    console.log(err)
  }
}

/*
 *   Delete feed
 */
export async function deleteFeed(feedId) {
  try {
    return setDoc(
      doc(db, `users/${UID}/instagramFeeds`, feedId),
      {
        archived: true,
        archivedOn: serverTimestamp(),
        accessToken: null,
        cachedPalettes: null,
        cachedColors: null,
      },
      { merge: true },
    )
  } catch (err) {
    console.log(err)
  }
}

/*
 *   Process upgrade
 */
export async function processUpgradeFromFree(feeds) {
  try {
    Object.values(feeds).forEach((feed) => {
      const breakpointNamedLayouts = feed.widgetSettings?.breakpoints
        ? Object.values(feed.widgetSettings.breakpoints).reduce((acc, curr) => {
            return [...acc, curr.galleryWallNamedLayout]
          }, [])
        : []

      if (
        breakpointNamedLayouts.includes('tribeca') &&
        feed.widgetSettings?.type === 'galleryWall'
      ) {
        updateDoc(doc(db, `users/${UID}/instagramFeeds`, feed.id), {
          maxPosts: 14,
        })
      }
    })
  } catch (error) {
    console.log(error)
  }
}

/*
 *   Reauthenticate
 */
export async function reauthenticate(password) {
  const user = auth.currentUser
  const credential = EmailAuthProvider.credential(user.email, password)

  return reauthenticateWithCredential(user, credential)
}

/*
 *   Update password
 */
export async function updatePassword(password) {
  return updatePasswordInFirebase(auth.currentUser, password)
}
