// transforms a v2 formatted feed into a v1 formatted feed
export function v1(processedFeed, feed) {
  processedFeed = structuredClone(processedFeed)

  // add "dimensions" field to posts
  processedFeed.posts = processedFeed.posts.map((post) => {
    return {
      ...post,
      dimensions: {
        width: post.sizes?.full?.width ?? null,
        height: post.sizes?.full?.height ?? null,
      },
    }
  })

  // Prune out sizes for API feeds
  if (feed.type === 'API') {
    processedFeed.posts.forEach((post) => {
      if (post.sizes) delete post.sizes
      post.children?.forEach((child) => {
        if (child.sizes) delete child.sizes
      })
    })
  }

  // Rename "posts" -> "media"
  processedFeed.media = processedFeed.posts
  delete processedFeed.posts

  // Remove profile data for basic source api feeds
  if (feed.sourceType?.includes('instagramBasic') && feed.type === 'API') {
    processedFeed = processedFeed.media
  }

  return processedFeed
}
